// config.js

const bizIcon = 'https://assets.cdn.filesafe.space/nH7ySxxq5J87b3N9XPVh/media/675385e76a09d9ed6bceb8ce.png';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://assets.cdn.filesafe.space/nH7ySxxq5J87b3N9XPVh/media/67523358988a5f0b589a23fb.png";
const imageTwo = "https://assets.cdn.filesafe.space/nH7ySxxq5J87b3N9XPVh/media/6752337d29695a7d666b81d5.png";
const imageThree = "https://assets.cdn.filesafe.space/nH7ySxxq5J87b3N9XPVh/media/675234c101fcb380a39957ea.png";
const imageFour = "https://assets.cdn.filesafe.space/nH7ySxxq5J87b3N9XPVh/media/675234c101fcb342fd9957eb.png";
const imageFive = "https://assets.cdn.filesafe.space/nH7ySxxq5J87b3N9XPVh/media/675234c19fe0bad42f843fd1.png";

const redirectLink = "https://jrpheatingcoolingllc.com/";

export const siteConfig = {
  title: "JRP Heating and Cooling LLC",
  metaDescription: "JRP Heating and Cooling LLC",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-blue-600"
  },
  logo: {
    src: bizIcon,
    alt: "JRP Heating and Cooling LLC Icon",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "JRP Heating and Cooling LLC",
    headline: "Your Trusted HVAC Professionals in Elizabeth, NJ",
    address: "Elizabeth, NJ 07202",
    description: `
        Since 2000, JRP Heating and Cooling LLC has been delivering exceptional HVAC services in Elizabeth, NJ, and surrounding areas. Specializing in heating, ventilation, and air conditioning solutions, our team of certified professionals is committed to ensuring your comfort year-round. With decades of industry experience, we provide reliable, efficient, and cost-effective services tailored to meet your needs. From installations and repairs to regular maintenance, trust JRP Heating and Cooling LLC to keep your home or business comfortable and energy-efficient.
    `,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Your Trusted HVAC Professionals in Elizabeth, NJ",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "Over 20 Years of Expertise: Delivering reliable and professional HVAC solutions since 2000.",
    },
    {
        description: 
          "Certified Professionals: Our skilled technicians ensure every installation, repair, and maintenance job is completed with precision and care.",
    },
    {
        description: 
          "Energy-Efficient Solutions: We prioritize eco-friendly and energy-saving HVAC systems to maximize your comfort and reduce costs.",
    },
  ],
  services: {
    sectionTitle: "Our HVAC Services",
    description: 
      "JRP Heating and Cooling LLC specializes in comprehensive HVAC solutions, delivering exceptional services tailored to meet residential and commercial needs in Elizabeth, NJ, and surrounding areas. From installations to maintenance, our expertise ensures your systems run efficiently, reliably, and comfortably year-round.",
    callouts: [
      {
        name: 'HVAC Installation, Repair, Replacement, and Maintenance',
        description: 
          "Seamless installations and expert repairs tailored to your needs. Whether upgrading performance or ensuring longevity with proactive maintenance, we keep your systems running efficiently all year.",
        imageSrc: imageTwo,
        imageAlt: 'Technician performing HVAC installation for a residential property.',
      },
      {
        name: 'Air Filters, Air Quality, and Heat Pumps',
        description: 
          "Personalized solutions to enhance indoor air quality and temperature control. Our installation, repair, and maintenance services ensure a healthier and more comfortable environment every day.",
        imageSrc: imageThree,
        imageAlt: 'Heat pump installation improving air quality and climate control.',
      },
      {
        name: 'Energy Efficiency Checkups and Full System Commissioning',
        description: 
          "Boost performance and reduce energy costs with efficiency-focused services. Our energy audits and system commissioning maximize functionality and deliver smarter, more cost-effective systems.",
        imageSrc: imageFour,
        imageAlt: 'Technicians conducting an energy efficiency checkup on an HVAC system.',
      },
    ],
    otherServices: [
      "Custom Duct Manufacturing",
    ]
  },
  about: {
    sectionTitle: "About Us",
    description:   
      "JRP Heating and Cooling LLC has been the trusted name in HVAC services in Elizabeth, NJ, since 2000. Our family-owned business is dedicated to delivering high-quality heating and cooling solutions tailored to your unique needs. We pride ourselves on our commitment to customer satisfaction, attention to detail, and expertise in energy-efficient systems. Fully licensed and insured, we ensure every project is completed to the highest standards. Contact us today to experience HVAC services that combine professionalism with personalized care.",
    image: {
      src: imageFive,
      alt: "JRP Heating and Cooling LLC team providing HVAC solutions in Elizabeth, NJ.",
      width: 1200,
      height: 800,
    },
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "Elizabeth, NJ 07202",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3086.8330190996715!2d-74.21727718477129!3d40.66399197933642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3aa46f0e800ff%3A0xabcdef123456789!2sElizabeth%2C%20NJ%2007202!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=Elizabeth,%20NJ,%2007202',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=Elizabeth,+NJ+07202&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/Elizabeth,+NJ+07202/@40.663992,-74.217277,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/Elizabeth,+NJ+07202/@40.663992,-74.217277,15z',
      },
    ],
  },
  contact: {
    sectionTitle: "Reach Out to Us",
    description: 
      "Experience the ultimate in comfort and performance with JRP Heating and Cooling LLC. Whether you need expert advice, want to schedule a service, or request a free estimate, our friendly and knowledgeable team is here to help. Let us ensure your home or business stays perfectly climate-controlled year-round. Contact us today and take the first step toward unmatched HVAC excellence!",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (862) 579-1850",
    ],
    logo: bizIcon,
    emailRecipient: "info@jrpheatingcoolingllc.com"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


